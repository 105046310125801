import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order_freezer/freezer_scheduled`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements_xls(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order_freezer/freezer_scheduled_excel`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order_freezer/freezer/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_transporters(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { 
            params: { perPage: 500, page: 1, companyType: 'SUPPLIER', providerType: 'TRANSPORT', only_active: true } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_laboratories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { 
            params: { perPage: 500, page: 1, companyType: 'SUPPLIER', providerType: 'LABORATORY', only_active: true } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/medical_order_freezer/freezer_scheduled/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    request_auth(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/medical_order_freezer/request_auth/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { 
            params: { perPage: 500, page: 1, companyType: 'ARL' } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_service_bundles(ctx, { arl_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/service_bundle/by_arl/${arl_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type, deep, parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/' + type, { 
            params: { perPage: 1000, deep: deep || 1, parent: parent }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 2 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, {parent}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 3, parent: parent } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
